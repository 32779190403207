import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  flexbox,
  layout,
  space,
  color,
  typography,
  border,
  position,
} from 'styled-system';

const Layout = styled.div`
  ${flexbox}
  ${layout}
  ${space}
  ${color}
  ${typography}
  ${border}
  ${position}
`;

const Box = React.forwardRef((props, ref) => {
  const { children } = props;
  return (
    <Layout ref={ref} {...props}>
      {children}
    </Layout>
  );
});

Box.propTypes = {
  children: PropTypes.node,
};

Box.defaultProps = {
  children: null,
};

export default Box;
