import loadable from '@loadable/component';
import Loading from 'components/Loading';

// Login
const Login = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "login" */ 'pages/Login'
    ),
  { fallback: <Loading /> }
);

export { Login };
export default undefined;
