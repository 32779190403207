/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { Layout, Dropdown, Menu } from 'antd';
import { Outlet, Navigate } from 'react-router-dom';
import { Flex } from 'baseComponents/Layout';
import { useQuery } from 'react-query';
import { getMe } from 'api/auth';
import SideMenu from './components/SideMenu';

const { Header, Content } = Layout;

const Title = styled.span`
  font-size: 18px;
  color: white;
  margin-left: 16px;
`;

const UserBlock = styled.div`
  height: 100%;
  font-size: 16px;
  color: white;
  cursor: pointer;
  user-select: none;
`;

function MainLayout() {
  const accessToken = localStorage.getItem('accessToken');
  const userName = localStorage.getItem('userName');

  const {
    data: getMeResp,
    isLoading: isGetMeLoading,
    isSuccess: isGetMeSuccess,
  } = useQuery('getMe', getMe);

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  const sidebarWidth = 210;
  const meData = getMeResp?.data?.data || {};

  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Flex
          px="24px"
          alignItems="center"
          justifyContent="space-between"
          height={64}
        >
          <Flex>
            <Title>Unitech - 案件管理系統</Title>
          </Flex>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="logout" onClick={logout}>
                  登出
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <UserBlock>{userName || ''}</UserBlock>
          </Dropdown>
        </Flex>
      </Header>
      <Layout className="site-layout" style={{ marginLeft: sidebarWidth }}>
        <SideMenu width={sidebarWidth} meData={meData} />
        <Content style={{ padding: '24px 16px 0', overflow: 'scroll' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

MainLayout.propTypes = {};

export default MainLayout;
