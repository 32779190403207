import requestAgent from './requestAgent';

const { request } = requestAgent;

const login = ({ account, password }) => {
  return request({
    method: 'POST',
    endpoint: `/api/auth/login`,
    data: { email: account, password },
    auth: false,
    json: true,
  });
};

const getMe = () => {
  return request({
    method: 'GET',
    endpoint: `/api/me`,
    data: {},
    auth: true,
    json: true,
  });
};

export { login, getMe };
export default undefined;
