import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { Flex } from 'baseComponents/Layout';

const { Sider } = Layout;
const { SubMenu } = Menu;

function SideMenu({ width, meData }) {
  const navigate = useNavigate();
  const onSelect = (item) => {
    navigate(item.key);
  };

  return (
    <Sider
      width={width}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <Flex height="100%" flexDirection="column">
        <Menu
          openKeys={['task', 'admin']}
          onClick={onSelect}
          style={{ flex: 1 }}
          defaultSelectedKeys={[window.location.pathname]}
          defaultOpenKeys={[window.location.pathname.split('/')[1]]}
          mode="inline"
        >
          <SubMenu key="task" title="案件管理">
            <Menu.Item key="/task">案件列表</Menu.Item>
          </SubMenu>
          {meData?.roleId === 1 && (
            <SubMenu key="admin" title="管理控制台">
              <Menu.Item key="/admin/tag">標籤管理</Menu.Item>
              {/* <Menu.Item key="/admin/flow">流程管理</Menu.Item> */}
              <Menu.Item key="/admin/user">使用者管理</Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Flex>
    </Sider>
  );
}

SideMenu.propTypes = {
  width: PropTypes.number.isRequired,
  meData: PropTypes.objectOf(PropTypes.any),
};

SideMenu.defaultProps = {
  meData: {},
};

export default SideMenu;
