import loadable from '@loadable/component';
import Loading from 'components/Loading';

// TaskList
const TaskList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "tasklist" */ 'pages/TaskList'
    ),
  { fallback: <Loading /> }
);

// TaskDetail
const TaskDetail = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "task-detail" */ 'pages/TaskDetail'
    ),
  { fallback: <Loading /> }
);

export { TaskList, TaskDetail };
export default undefined;
