import loadable from '@loadable/component';
import Loading from 'components/Loading';

// Task
const Tag = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "tag" */ 'pages/Admin/Tag'
    ),
  { fallback: <Loading /> }
);

// const Pipeline = loadable(
//   () =>
//     import(
//       /*  webpackPrefetch: true, webpackChunkName: "pipeline" */ 'pages/Admin/Pipeline'
//     ),
//   { fallback: <Loading /> }
// );

const User = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "user" */ 'pages/Admin/User/List'
    ),
  { fallback: <Loading /> }
);

export { Tag, User };
export default undefined;
