import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from 'components/MainLayout';
import { Login } from './auth';
import { Tag, User } from './admin';
import { TaskList, TaskDetail } from './task';

function AppRoutes() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/*" element={<MainLayout />}>
        <Route path="tasks" element={<TaskList />} />
        <Route path="tasks/:taskId" element={<TaskDetail />} />
        <Route path="*" element={<Navigate to="/tasks" />} />
      </Route>
      <Route path="/admin/*" element={<MainLayout />}>
        {/* <Route path="pipeline" element={<Pipeline />} /> */}
        <Route path="tag" element={<Tag />} />
        <Route path="user" element={<User />} />
        <Route path="*" element={<Navigate to="/admin/tag" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
