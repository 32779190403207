import axios from 'axios';
// import { notification } from 'antd';

const API_HOST = process.env.REACT_APP_API_HOST;

export default {
  request: ({
    baseURL = API_HOST,
    method = 'get',
    endpoint = '',
    params = {},
    data = {},
    uri = '',
    auth = false,
    json = true,
    headers = [{ key: null, value: null }],
    responseType = 'json',
  }) => {
    // Inject JWT Token
    if (auth) {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.push({
          key: 'Authorization',
          value: `Bearer ${token}`,
        });
      }
    }

    // ContentType
    if (json) {
      headers.push({
        key: 'Content-Type',
        value: 'application/json',
      });
    }

    let requestHeaders = {};
    if (headers.length) {
      requestHeaders = headers.reduce((pre, next) => {
        const { key, value } = next;
        if (key && value) {
          const tmp = {};
          tmp[key] = value;
          return { ...pre, ...tmp };
        }
        return pre;
      }, {});
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );

    return axios({
      headers: requestHeaders,
      responseType,
      ...(uri ? { url: uri } : { baseURL, url: endpoint }),
      ...(method.toUpperCase() === 'GET'
        ? { params }
        : { method, params, data }),
    });
  },
};
