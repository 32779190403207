import styled from 'styled-components';
import { Spin } from 'antd';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Loading() {
  return (
    <Wrapper>
      <Spin spinning />
    </Wrapper>
  );
}

export default Loading;
